module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132871388-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":true,"database":true,"firestore":false,"storage":false,"messaging":false,"functions":true,"performance":false},"credentials":{"apiKey":"AIzaSyD1EsjLsKYRQhwPE08FnY7jER3SQ0XY90c","authDomain":"pelatum-labs.firebaseapp.com","databaseURL":"https://pelatum-labs.firebaseio.com","projectId":"pelatum-labs","storageBucket":"pelatum-labs.appspot.com","messagingSenderId":"506946725149","appId":"1:506946725149:web:39cc4e55bd1d7a20842df4"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    }]
